import React, { useState, useEffect, useRef } from "react";
import "./Ipkl.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import player1 from "./sahul kumar 1.png";
import centerimg from "./centernew.png";

function Player() {
  const [playerData, setPlayerData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFading, setIsFading] = useState(false); // Track fade state
  const itemRefs = useRef([]);
  const thumbnailContainerRef = useRef(null);
  const maxVisibleThumbnails = 9; // Number of visible thumbnails at a time

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const response = await fetch(
          "https://dev-ipkl.ipkl.in/api/resource/Player%20Details?fields=[%22*%22]"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPlayerData(data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, []);

  // Set up an interval to auto-advance the current index
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext(); // Use the handleNext function for auto advance
    }, 5000); // Change every 5 seconds

    return () => clearInterval(interval);
  }, [playerData.length]);

  useEffect(() => {
    if (thumbnailContainerRef.current && itemRefs.current[currentIndex]) {
      // Center the active thumbnail
      thumbnailContainerRef.current.scrollTo({
        left:
          itemRefs.current[currentIndex]?.offsetLeft -
          thumbnailContainerRef.current.clientWidth / 2 +
          itemRefs.current[currentIndex]?.clientWidth / 2,
        behavior: "smooth",
      });
    }
  }, [currentIndex, playerData.length]);

  useEffect(() => {
    AOS.init({ duration: 800 }); // Initialize AOS with a smooth duration
    AOS.refresh(); // Refresh after state changes
  }, [currentIndex]);

  const handlePrev = () => {
    setIsFading(true); // Start fade out
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? playerData.length - 1 : prevIndex - 1
      );
      setIsFading(false); // End fade in after the image change
    }, 500); // Duration of fade-out matches the CSS transition
  };

  const handleNext = () => {
    setIsFading(true); // Start fade out
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % playerData.length);
      setIsFading(false); // End fade in after the image change
    }, 500); // Duration of fade-out matches the CSS transition
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Function to ensure infinite loop for thumbnails
  const getInfiniteThumbnails = () => {
    const thumbnails = [];
    const half = Math.floor(maxVisibleThumbnails / 2);

    for (let i = -half; i <= half; i++) {
      const index = (currentIndex + i + playerData.length) % playerData.length;
      thumbnails.push(playerData[index]);
    }

    return thumbnails;
  };

  const visibleThumbnails = getInfiniteThumbnails();

  return (
    <div className="playersection">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 col-12 align-self-center">
            <div className="box-container">
              <h3>OUR SQUAD </h3>
              <div className="image-container">
                <button onClick={handlePrev} className="prev-button">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
                <div className="spacevertical"></div>

                <div className="boxshadow"></div>
                <img
                  src={`https://dev-upkl.upkl.in/${playerData[currentIndex]?.image || player1}`}
                  alt="Hero Icon"
                  className={`playercard ${isFading ? "fade-out" : "fade-in"}`}
                  data-aos="fade-in" // AOS attribute for smooth fade
                  data-aos-duration="800" // Custom duration
                />
                <button onClick={handleNext} className="next-button">
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-12">
            <div className="player-content">
              <h5 data-aos="fade-up" data-aos-duration="800">{playerData[currentIndex]?.players_name}</h5>
              <div className="playerbio">
                <p data-aos="fade-up" data-aos-duration="800">
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without relying on meaningful content
                </p>
              </div>
              <div className="boxstats">
                <div className="box1" data-aos="fade-up" data-aos-duration="800">
                  <p>Match Stats</p>
                  <h2>{playerData[currentIndex]?.match_1 || "N/A"}</h2>
                </div>
                <div className="box1" data-aos="fade-up" data-aos-duration="800">
                  <p>Match Stats</p>
                  <h2>{playerData[currentIndex]?.match_2 || "N/A"}</h2>
                </div>
                <div className="box1" data-aos="fade-up" data-aos-duration="800">
                  <p>Match Stats</p>
                  <h2>{playerData[currentIndex]?.match_3 || "N/A"}</h2>
                </div>
              </div>
              {/* <div className="viewprofile" data-aos="fade-up" data-aos-duration="800">
                <button>View Profile  <span>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                </span></button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">

       
        <div className="col-12 align-self-end p-0">
          <div className="playerthumbnail" ref={thumbnailContainerRef}>
            {visibleThumbnails.map((item, index) => (
              <div className="thumbnail-container" key={index}>
                <img
                  src={`https://dev-upkl.upkl.in/${item.image || player1}`}
                  alt={`Item ${index + 1}`}
                  width="120px"
                  height="120px"
                  className={`item-image ${
                    index === Math.floor(maxVisibleThumbnails / 2) ? "active" : ""
                  }`}
                  onClick={() => setCurrentIndex(
                    (currentIndex + index - Math.floor(maxVisibleThumbnails / 2) + playerData.length) % playerData.length
                  )}
                  ref={(el) => (itemRefs.current[(currentIndex + index) % playerData.length] = el)}
                />
                {index === Math.floor(maxVisibleThumbnails / 2) && (
                  <img src={centerimg} alt="Arrow" className="arrow" />
                )}
              </div>
            ))}
          </div>
        
        </div>
       

      </div>
      <br />
    </div>
  );
}

export default Player;
