import React from "react";
import Breadcumb from "./Breadcumb";
import arrow from "./arrowabtract.png";
import trophy from './trophyimg.svg'
import Ipkltv from "./Ipkltv";
export default function Indiatour() {
    const backgroundStyle = {
        backgroundImage: "url('https://plus.unsplash.com/premium_photo-1661919589683-f11880119fb7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
  return (
    <>
      <Breadcumb
        page="India Tour"
        title="Upcoming Thrills: Matches and Events"
      />
<section id="indiatour">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="citylist">
              <ul class="nav flex-column custom-city-list">
                <li class="nav-item">
                  <a class="nav-link active-city" href="#">
                    Delhi
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Bangalore
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Mumbai
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Rajasthan
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Haryana
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Gujarat
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Punjab
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Uttar Pradesh
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8"></div>
        </div>
      </div>
      </section>




      <section id="trophydescription">
      {/* <img src={trophy}  className="img-fluid trophyimg"/> */}

        <div className="container">
            <div className="row">

                <div className="col-12 col-md-6">
                    <div className="trophy-left">
                    <div className='headingsection'>
        <img src={arrow} className="img-fluid"/>
        <h4 className="text-white">MATCH SCHEDULE</h4>
      </div>

                        <p>The Indian Premier Kabaddi League (IPKL) stands as a pioneering initiative aimed at reinvigorating the significance of Kabaddi in India. Serving as a dedicated platform, IPKL is committed to fostering the talents of local Kabaddi athletes, providing them with opportunities for growth and recognition. 
</p><p>
The Indian Premier Kabaddi League (IPKL) stands as a pioneering initiative aimed at reinvigorating the significance of Kabaddi in India. </p>
                    </div>
                </div>
                <div className="col-6 col-md-6">
                <div className="trophy-left">
             <br/>
             <br/>
             <br/>
                        <p>The Indian Premier Kabaddi League (IPKL) stands as a pioneering initiative aimed at reinvigorating the significance of Kabaddi in India. Serving as a dedicated platform, IPKL is committed to fostering the talents of local Kabaddi athletes, providing them with opportunities for growth and recognition. 
</p><p>
The Indian Premier Kabaddi League (IPKL) stands as a pioneering initiative aimed at reinvigorating the significance of Kabaddi in India. </p>
                    </div>
                </div>

            </div>
        </div>


      </section>

      <section className="playbtn">
      <div class="container-fluid p-0">
      <div className="city-tour" style={backgroundStyle}>
      <div class="overlay-content">
      <h2>Explore the City</h2>
      <p>The Indian Premier Kabaddi League (IPKL) stands as a pioneering initiative aimed at reinvigorating the significance of Kabaddi in India. Serving as a dedicated platform, IPKL is committed to fostering the talents of local Kabaddi athletes, providing them with opportunities for growth and recognition. </p>
    </div>
    <a href="#" class="play-btn">
      <i class="fa fa-play-circle"></i> 
    </a>
    
  </div>
  <Ipkltv/>

</div>
      </section>
    </>
  );
}
