// Outlets.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Comingsoon from "./Comingsoon";
import Contact from "./Contact";
import StandingPage from "./StandingPage";
import Team from "./Team";
import Matches from "./Matches";
import Blog from "./Blog";
import BlogDetail from "./BlogDetail";
import BlogDetails from "./BlogDetails";
import New from "./New";
import Gallery from "./Gallery";
import Photodetails from "./Photodetails";
import Indiatour from "./Indiatour";
import Player from "./Player";
import Teamplayer from "./Teamplayer";

function Outlets() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/coming-soon" element={<Comingsoon />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/standings" element={<StandingPage />} />
      <Route path="/teams" element={<Team />} />
      <Route path="/matches" element={<Matches />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/IPKL-Mega-Launch-2024" element={<BlogDetail />} />
      <Route path="/single-blog" element={<BlogDetails/>} />
      <Route path="/news" element={<New/>} />
      <Route path="/photodetails" element={<Photodetails/>} />
      <Route path="/gallery" element={<Gallery/>} />
      <Route path="/india-tour" element={<Indiatour/>} />
      <Route path="/players" element={<Teamplayer/>} />






    </Routes>
  );
}

export default Outlets;
