import React from 'react'
import Breadcumb from './Breadcumb'
import Footer from './Footer'
import Header from './Header'
import arrow from './arrowabtract.png'
import { Link } from 'react-router-dom'
import blogimg from './IPKL-30.jpg'
export default function BlogDetail() {
  return (
  <>
 



     {/* <Breadcumb page="Blog Detail"   title="Meet our Best Team of IPKL 2024 "/> */}

    <div className='bluespace'></div>

     <div className="blog-description">
        <div className="row">
          <div className="col-12">
          </div>

          <div className="para">

            <h1>
              IPKL Mega Launch 2024: Unveiling the Trophy, Jersey, and Teams on
              12th September
            </h1>

            <div className="socialicone">
                    <ul className="d-flex">
                        <li>
                          <Link
                            to="https://www.facebook.com/indianpremierkabaddileague/"
                            target="_blank"
                          >
                            <i
                              class="fa fa-facebook-square"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            to="https://www.instagram.com/ipklofficial/"
                            target="_blank"
                          >
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li className="">
                          <i class="fa fa-linkedin " aria-hidden="true"></i>
                        </li>

                        <li>
                          <i class="fa fa-youtube-play" aria-hidden="true"></i>
                        </li>
                      </ul>
                    </div>

            <img src={blogimg} className="img-fluid bogimage" />
            <p>
              The stage is set for the highly anticipated IPKL Mega Launch 2024
              on 12th September at the Ramada Plaza by Wyndham, Chandigarh
              Zirakpur. This grand event will mark a major milestone in the
              upcoming season as we proudly reveal the official trophy, showcase
              the jerseys, and introduce the teams that will compete in the
              league.
            </p>

            <h2>The Trophy</h2>

            <p>
              The spotlight of the IPKL Mega Launch will shine on the unveiling
              of the IPKL Trophy, a symbol of excellence, resilience, and
              sportsmanship. This trophy represents the pinnacle of achievement
              in kabaddi, a reward for the relentless dedication of the teams
              that will battle it out this season. The unveiling of this
              masterpiece will set the tone for the fierce competition ahead.
            </p>

            <h2>The Jersey Reveal: Fresh Designs for a New Era</h2>

            <p>
              The teams' official jerseys will also be revealed during the
              event. These innovative designs blend performance-driven
              technology with bold aesthetics, representing the spirit of each
              team. These jerseys are not just uniforms; they are symbols of
              pride, unity, and the energy that kabaddi brings to millions of
              fans across the nation.
            </p>

            <h2>Meet the Teams: A Glimpse of the Season Ahead</h2>

            <p>
              The IPKL Mega Launch 2024 will also introduce the teams ready to
              compete for glory in the upcoming season. This year’s roster is
              packed with talent, bringing together some of the best kabaddi
              players from across the country. The teams' strategies, passion,
              and determination will be on full display, giving fans a preview
              of the intense action to come.
            </p>

            <h2>Venue: Ramada Plaza by Wyndham Chandigarh Zirakpur</h2>

            <p>
              The grand launch event will take place at Ramada Plaza by Wyndham,
              Chandigarh Zirakpur, a venue known for its elegance and
              world-class facilities, making it the perfect location for such an
              important occasion.
            </p>

            <p>
              Stay tuned for more updates as the IPKL Mega Launch on 12th
              September draws closer!
            </p>
          </div>
        </div>
      </div>


  </>
  )
}
