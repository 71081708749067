import React, { useState, useEffect } from "react";
import img1 from './newimage.png';
import large from './largeimag.png';
import { Link } from 'react-router-dom'
import img2 from './imagenewsa.png'
import OwlCarousel from 'react-owl-carousel';
import arrow from './arrowabtract.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img3 from './video.png'
import img4 from './01 (6).jpg'
import img5 from'./04 (4).jpg'
import img6 from './02 (5).jpg'
export default function News() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);


 

  const options1 = {
    items:2,
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1.5,
        margin: 20,
       },
      600: { items: 1.2 ,},
      margin: 20,

      1000: { items: 2},
    },
  };


  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-ipkl.ipkl.in/api/resource/News%20and%20Updates?fields=["*"]&limit=5' // Added limit=5
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Fetched data:", data); // Log the fetched data
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  return (
    <>
      <section id="news">
        <div className="container-fluid">
    <div className="row">
      <div className="col-md-7 col-12 p-0">
        <div className="leftimgbg">

        <div class="news-card">
    <div class="news-card-image">
      <img src="https://dev-ipkl.ipkl.in//files/5.jpg" alt="News Image"/>
      <div class="news-card-gradient"></div>
      <div class="news-card-content">
        <div className="date">
          <p>14 Sept 2024</p>
        </div>
        <h2>Exciting season of Kabaddi kicks-off, IPKL trophy launched</h2>

         <div className="width-fit">
          <Link to="https://aninews.in/news/business/exciting-season-of-kabaddi-kicks-off-ipkl-trophy-launched20240914155620/" target="_blank">
          <p className="readmore">READ MORE</p>

          </Link>
           <div className="coloryellowstip"></div>
           </div>
      </div>
    </div>
  </div>
        </div>
      </div>

      <div className="col-md-5 col-12 p-0 d-flex flex-column justify-content-between">
        <div className="row ">
          <div className="news-right">
            <p className="date">Oct 20,2024</p>
            <h4>Indian Premier Kabaddi League (IPKL) Mega Launch Event 2024
</h4>

           <div className="width-fit">
           <p className="readmore">READ MORE</p>
           <div className="coloryellowstip"></div>
           </div>
          </div>
        </div>

        <div className="row">
        <OwlCarousel className='owl-theme' {...options1}>
  <div className='item'>
  <div class="right-news">
        <img src={img4} alt="Sample Image"/>
        <div class="right-news-overlay"></div>
        <div class="right-news-text">
        <p className="date">Sept 17,2024</p>

        <Link to="https://sports.ndtv.com/kabaddi/haryana-hurricanes-rajasthan-rulers-delhi-dragons-unveil-captains-and-jerseys-for-2024-indian-premier-kabaddi-league-6585079" target="_blank">
        <h1>Three Teams Unveil Captains Jerseys For Inaugural IPKL</h1>

        </Link>
        </div>
  </div>
  </div>


  <div className='item'>
  <div class="right-news">
        <img src={img5} alt="Sample Image"/>
        <div class="right-news-overlay"></div>
        <div class="right-news-text">
        <p className="date">Sept 15,2024</p>

        <Link to="https://www.sportsadda.com/kabaddi/news/indian-premier-kabaddi-league-ipkl-2024-start-date-team-names" target="_blank">
        <h1>IPKL 2024 commences on October 4..</h1>

        </Link>
        </div>
  </div>
  </div>



  <div className='item'>
  <div class="right-news">
        <img src={img6} alt="Sample Image"/>
        <div class="right-news-overlay"></div>
        <div class="right-news-text">
        <p className="date">Sept 13,2024</p>

        <Link to="https://www.ptinews.com/press-release/indian-premier-kabaddi-league-ipkl-mega-launch-event/1812635" target="_blank">
        <h1>IPKL Mega Launch Event 2024      </h1>

        </Link>
        </div>
  </div>
  </div>
        </OwlCarousel>
        </div>
      </div>
    </div>
        </div>
      </section>
    </>
  );
}
