import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Breadcumb from "./Breadcumb";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import Standing from "./Standing";

import { Helmet } from "react-helmet";
export default function StandingPage() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>IPKL 2024 Standings - Live Score Updates and Points Table</title>
        <link rel="canonical" href="https://ipkl.in/standings" />
        <meta
          name="description"
          content="Check the IPKL 2024 standings, including live score updates and the current points table. Get the latest kabaddi scores and standings here.
"
        />
        <meta
          name="keywords"
          content=" Ipkl standings 2024
IPKL points table 2024
IPKL live score
kabaddi live updates

"
        />
      </Helmet>
      <Breadcumb
        page="Standing"
        title="Stay connected for the latest match updates"
      />
      <Standing />
    </>
  );
}
