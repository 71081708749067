import React, { useState, useEffect } from "react";
import logo from "./logo.png";
import Breadcumb from "./Breadcumb";
import arrow from "./arrowabtract.png";

export default function Standing() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch('https://dev-ipkl.ipkl.in/api/resource/Standings?fields=["*"]');
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedData = data.data.sort((a, b) => a.position - b.position);
        setStandings(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  return (
    <>
      <section id="standing">
        {/* <div className="headingstanding">
          <h4>MATCH STANDINGS</h4>
        </div> */}

        <div className='headingsection mx-auto'>
        <img src={arrow} className="img-fluid"/>
        <h4 className="text-white">POINTS TABLE </h4>
      </div>
        <div id="table">
          <table class="table table-striped table-bordered">
            <thead class="custom-header">
              <tr>
                <th>POS</th>
                <th>Logo</th>
                <th className="tablwidth1">Team Name</th>
                <th></th>
                <th>P</th>
                <th>W</th>
                <th>L</th>
                <th>D</th>
                <th>+/-</th>
                <th className="tablwidth">Forms</th>
                <th>Pts.</th>
              </tr>
            </thead>
            <tbody>
              {standings.length > 0 ? (
                standings.map((team, index) => (
                  <tr>
                     <td className="text-center">{index + 1}</td>
                    <td>
                    <img
                            src={`https://dev-ipkl.ipkl.in/${team.image}`}
                            alt={team.team}
                          />
                    </td>
                    <td className="text-left">{team.team}</td>
                    <td></td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>
                      <ul className="d-flex">
                        <li class="form-toss">
                          <p>-</p>
                        </li>
                        <li class="form-toss">
                          <p>-</p>
                        </li>
                        <li class="form-toss">
                          <p>-</p>
                        </li>
                        <li class="form-toss">
                          <p>-</p>
                        </li>
                        <li class="form-toss">
                          <p>-</p>
                        </li>
                        <li class="form-toss">
                          <p>-</p>
                        </li>
                      </ul>
                    </td>
                    <td>0</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">Loading...</td>
                  <td colSpan="3"></td>
                  <td colSpan="3"></td>
                  <td className="mobilenone"></td>
                  <td className="mobilenone"></td>
                  <td className="mobilenone"></td>
                  <td className="mobilenone"></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
