import React, { useState, useEffect } from "react";
import Breadcumb from './Breadcumb'
import Footer from './Footer'
import Header from './Header'
import arrow from './arrowabtract.png'
import { Link } from 'react-router-dom'
import blogimg from './IPKL-30.jpg'
export default function BlogDetails() {

    const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const id = params.get("name");
        const response = await fetch(
            `https://dev-ipkl.ipkl.in/api/resource/IPKL%20Bog/${id}?fields=["*"]`
        );
        console.log(response, "aas");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchStandings();
  },[]);
  return (
  <>
 



     {/* <Breadcumb page="Blog Detail"   title="Meet our Best Team of IPKL 2024 "/> */}

    <div className='bluespace'></div>

     <div className="blog-description">
        <div className="row">
          <div className="col-12">
          </div>

          <div className="para">

            <h1>
            {standings.title}
            </h1>

            <div className="socialicone">
                    <ul className="d-flex">
                        <li>
                          <Link
                           to={`https://www.facebook.com/sharer/sharer.php${standings.title}`}
                            target="_blank"
                          >




                            <i
                              class="fa fa-facebook-square"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link
                            to="https://www.instagram.com/ipklofficial/"
                            target="_blank"
                          >
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li className="">
                          <i class="fa fa-linkedin " aria-hidden="true"></i>
                        </li>

                        <li>
                          <i class="fa fa-youtube-play" aria-hidden="true"></i>
                        </li>
                      </ul>
                    </div>

            <img  src={`https://dev-ipkl.ipkl.in/${standings.banner_image}`} className="img-fluid bogimage" />
          <p
                    dangerouslySetInnerHTML={{
                      __html: standings.blog_description,
                    }}
                  ></p>
          </div>
        </div>
      </div>


  </>
  )
}
