import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcumb from "./Breadcumb";
// import Lightbox from "react-image-lightbox";
// import "react-image-lightbox/style.css";

export default function Photodetails() {
  const breadcumb = "https://dev-ipkl.ipkl.in//files/breadcumb1.png";
  const [galleryItem, setGalleryItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false); // To track if the lightbox is open
  const [photoIndex, setPhotoIndex] = useState(0); // To track the current image index in lightbox

  useEffect(() => {
    const fetchGalleryItem = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        const response = await fetch(
          `https://dev-ipkl.ipkl.in/api/resource/IPKL%20Gallery/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setGalleryItem(data.data); // Store the fetched item's "data" object in state
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Always set loading to false whether success or failure
      }
    };
    fetchGalleryItem();
  }, []); // Empty dependency array means this effect runs only once, like componentDidMount

  return (
    <>
      <Breadcumb page="IPKL Gallery" title="Upcoming Thrills: Matches and Events" />

      <Helmet>
        <title>IPKL Gallery</title>
      </Helmet>

      <section className="galleryipkl" id="gallery">
        <div className="container-fluid">
          <div className="row">
            {loading ? (
              <p>Loading...</p>
            ) : galleryItem && galleryItem.images ? (
              galleryItem.images.length > 0 ? (
                galleryItem.images.map((image, index) => (
                  <div key={index} className="col-md-4 col-12">
                    <div className="card position-relative GALLERYDETAILS">
                      <div className="overlay"></div>
                      <img
                        src={`https://dev-ipkl.ipkl.in/${image.image}`}
                        className="card-img-top img-fluid photodetails"
                        alt={image.alt}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(index);
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <p>No images found.</p>
              )
            ) : (
              <p>No data found.</p>
            )}
          </div>
        </div>
      </section>

      {/* {isOpen && galleryItem.images && (
        <Lightbox
          mainSrc={`https://dev-ipkl.ipkl.in/${galleryItem.images[photoIndex].image}`}
          nextSrc={`https://dev-ipkl.ipkl.in/${galleryItem.images[(photoIndex + 1) % galleryItem.images.length].image}`}
          prevSrc={`https://dev-ipkl.ipkl.in/${galleryItem.images[(photoIndex + galleryItem.images.length - 1) % galleryItem.images.length].image}`}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + galleryItem.images.length - 1) % galleryItem.images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryItem.images.length)
          }
        />
      )} */}
    </>
  );
}
