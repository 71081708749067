import React from "react";
import { Link } from "react-router-dom";
import footerlogo from "./Logo png.png";
import Sponsor from "./Sponsor";
import footerlogo1 from "./Frame 222.svg";
import ModalComponent from "./ModalComponent";
export default function Footer() {
  return (
    <>

    {/* <ModalComponent/> */}
      <Sponsor />

      <section id="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="footerlogo">
              <img src={footerlogo} className="img-fluid footerlogo" />
            </div>
    <p className="emailcontact"> Email :  indianpremierkabaddileague@gmail.com</p>
            <div className="footer-menu-link">
              <ul>
              <li>
                  <Link to="/">Home</Link>
                </li>
              <li>
                  {" "}
                  <Link to="/about">About Us</Link>
                </li>

             

                <li>
                  <Link to="/teams">Team</Link>
                </li>
                <li>
                  <Link to="/matches">Matches</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/standings">Standings</Link>
                </li>

                <li>
                  {" "}
                  <Link to="/news">News & Articles</Link>
                </li>

               
              </ul>
            </div>

            <img src={footerlogo1} className="img-fluid footerteams" />

            <div className="footer-menu-link">
              <ul className="d-flex">
                <li>
                  <Link
                    to="https://www.facebook.com/indianpremierkabaddileague/"
                    target="_blank"
                  >
                    <i class="fa fa-facebook-square" aria-hidden="true"></i>
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="https://www.instagram.com/ipklofficial/"
                    target="_blank"
                  >
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </Link>
                </li>
                <li className="">
                  <Link to="https://www.linkedin.com/showcase/indian-premier-kabaddi-league/" target="_blank">
                    <i class="fa fa-linkedin " aria-hidden="true"></i>
                  </Link>
                </li>

                <li>
                  <Link to="https://www.youtube.com/@IndianPremiereKabaddiLeague" target="_blank">
                    <i class="fa fa-youtube-play" aria-hidden="true"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>{" "}
        </div>
        <div className="copy-right">
          <p>
            © Copyright Indian Premier Kabaddi League 2024. All Rights Reserved.
          </p>
        </div>
      </section>
    </>
  );
}
