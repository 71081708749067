import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./App.css";
import banner1 from "./catch us live.jpg";
import banner0 from "./Banner (2).jpg";
import Banner2 from "./Banner2.jpg";

const BannerSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const thumbnails = [
    {
      id: 1,
      imgSrc: banner0,
      title: "Introducing the IPKL trophy, the pinnacle prize for the top 8 teams",
    },
    {
      id: 2,
      imgSrc: Banner2,
      title:"The wait is over! The Indian Premier Kabaddi League kicks off on 15th Feb 2025."
    }
  
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          setActiveIndex((prevIndex) => (prevIndex + 1) % thumbnails.length);
          return 0;
        }
        return prev + 12.5; // Update the progress every 1 second (8 sec to full)
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [thumbnails.length]);

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    setProgress(0);
  };

  return (
    <div className="banner-slider d-sm-none d-md-block">
      <OwlCarousel items={1} loop={false} autoplay={false} dots={false} nav={false} className="owl-theme" startPosition={activeIndex}>
        {thumbnails.map((banner, index) => (
          <div key={index} className="banner-item">
            <div className="gradient-overlay"></div>
            <img src={banner.imgSrc} alt={`Banner ${index + 1}`} className="banner-image" />
            <div className="carousel-content">
              <h2>{banner.title}</h2>
            </div>
          </div>
        ))}
      </OwlCarousel>

      <div className="thumbnail-container">
        {thumbnails.map((banner, index) => (
          <div
            key={index}
            className={`thumbnail ${index === activeIndex ? 'active' : ''}`}
            onClick={() => handleThumbnailClick(index)}
          >
            <img src={banner.imgSrc} alt={`Thumbnail ${index + 1}`} className="thumbnail-image" />
            {index === activeIndex && <div className="progress-bar" style={{ width: `${progress}%` }}></div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BannerSlider;
