import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import arrow from './arrowabtract.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import play from './playbtnnew.svg';
import { Link } from 'react-router-dom';

export default function Ipkltv() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch('https://dev-ipkl.ipkl.in/api/resource/IPKL%20Tv?fields=["*"]');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        setVideos(data.data || []); // Ensure that `data.data` is an array, or set an empty array as fallback
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  const options = {
    items: 6,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1.9 },
      600: { items: 2 },
      1000: { items: 5.2 },
    },
  };

  return (
    <section className='ipkl-tv'>
      <div className='container-fluid p-0'>
          <div className='headingsection mx-auto'>
            <img src={arrow} className='img-fluid' alt='Arrow' />
            <h4 className='text-white'>IPKL TV</h4>
        </div>

        <div className='videoplay'>
          {videos.length > 0 ? (
            <OwlCarousel className='owl-theme' {...options}>
              {videos.map((video) => (
                <div className='item' key={video.id}>
                 <Link to={video.url} target='_blank'>
                 
                 <div className='video-container'>
                    <img src={`https://dev-ipkl.ipkl.in/${video.video_banner}`} alt='Video Thumbnail' className='video-thumbnail' />
                    <div className='overlay'>
                      <div className='play-button'><img src={play} className='img-fluid'/></div>
                    </div>
                  </div>
                  
                  </Link>
                </div>
              ))}
            </OwlCarousel>
          ) : (
            <p className='text-white'>No videos available</p>
          )}
        </div>
      </div>
    </section>
  );
}
