import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import i1 from "./I1.avif";
import i2 from "./I2.avif";
import i3 from "./I3.avif";
import i4 from "./I4.avif";
import { Breadcrumb } from "./Breadcumb";
import Breadcumb from "./Breadcumb";
import { Link } from "react-router-dom";

export default function Gallery() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-ipkl.ipkl.in/api/resource/IPKL%20Gallery?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedData = data.data.sort((a, b) => a.match_day - b.match_day);

        setStandings(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  return (
    <>
      <Breadcumb
        page="IPKL Gallery"
        title="Upcoming Thrills: Matches and Events"
      />
      <section id="gallery">
        <div className="container-fluid">
          <div className="row">
            {standings.length > 0 ? (
              standings.map((team, index) => (
                <div class="col-md-4">
                  <Link to={`/Photodetails?id=${team.name}`}>
                    <div class="card gallery-card">
                      <img
                        src={`https://dev-ipkl.ipkl.in/${team.thumbnail_banner}`}
                        class="card-img-top"
                        alt="Gallery Image"
                      />
                      <div class="gallery-overlay">
                        <i class="fa fa-camera" aria-hidden="true"></i>
                        &nbsp;&nbsp;
                        {team.counter}
                      </div> 
                     
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p>Loading..</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
