import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Breadcumb from "./Breadcumb";
import playbtn from './Frame 285.png';
export default function Players() {
  const [players, setPlayers] = useState([]);
  const [team, setTeams] = useState([]);

  const [breadcumb, setBreadcumb] = useState("https://dev-ipkl.ipkl.in/files/breadcumb1.png");
const [id , setId] = useState('')
  useEffect(() => {
    fetchPlayers();
    fetchTeam();

  }, []);
  

  const fetchPlayers = async () => {
    try {

      const params = new URLSearchParams(window.location.search);
      const id = params.get("id");

      setId(id);
      const response = await fetch(
        `https://dev-ipkl.ipkl.in/api/resource/IPKL%20Players?filters=[["team",%20"=",%20"${id}"]]&fields=[%22*%22] &order_by=playername asc`

      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setPlayers(data.data); // Assuming the API response has a structure where data is an array of player objects
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  const fetchTeam = async () => {
    try {

      const params = new URLSearchParams(window.location.search);
      const id = params.get("id");

      setId(id);
      const response = await fetch(
        `https://dev-ipkl.ipkl.in/api/resource/IPKL%20Teams/${id}`

      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTeams(data.data); // Assuming the API response has a structure where data is an array of player objects
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes.</title>
        <meta name="title" content="Uttar Pradesh Kabaddi League (UPKL) - Empowering Kabaddi Athletes." />
        <meta
          name="description"
          content="Learn about the Uttar Pradesh Kabaddi League (UPKL), a dedicated platform committed to nurturing local Kabaddi talents. Understand UPKL's mission to engage audiences and reinforce Kabaddi's significance in India."
        />
        <link rel="canonical" href="http://upkl.in/about" />
      </Helmet>



      <section  className="teamsdeatilsection">
      <Breadcumb
        page={id}
        title="Upcoming Thrills: Matches and Events"
      />
      </section>

      <section className="aboutsection">
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">Players</h2>
                <div className="linetilte"></div>
              </div>
              <div className="abc">
                <img src="" className="textrightarrow" alt="Arrow" />
              </div>{" "}
            </div>
          </div>

          <div className="row">
            {players.map((player) => (
              <div className="col-md-3" key={player.name}>
                <div className={`playerscards ${player.team}`}
                >
                  <div className="playerbox">
                    <div className="jerseynumber">
                      <p>{player.jerseyno}</p>
                    </div>
                    <img src={player.compressed_img ? `https://dev-ipkl.ipkl.in/${player.compressed_img}` : 'https://dev-ipkl.ipkl.in/files/VECTOR-WEBSITE.png-03.png'} alt="Player Image" />


                  </div>
                  <div className="playerfooter">
                    <div className="row">
                      <div className="col-8">
                        <div className="playerfooter1">
                          <h5>{player.playername}</h5>
                          <p> {player.dob}</p>
                        </div>
                      </div>
                      <div className="col-4 align-self-center">
                        <div className="View Details">
                         <Link to={`/playerdetail?id=${player.name}`}>
                       <img src={playbtn} className="img-fluid"/></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    </>
  );
}
