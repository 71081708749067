import React,{useState } from "react";
import Header from "./Header";
import aboutplayer from "./aboutplayer.png";
import founderimg from "./founder-image.png";
import quote from "./quote.png";
import { useEffect } from "react";
import arrow from "./arrowabtract.png";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcumb from "./Breadcumb";
export default function Contact() { const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    number: '',
    subject: '',
    message: '',
    type: 'Media', // Default type
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTabChange = (type) => {
    setFormData({ ...formData, type }); // Update form type (Media or Sponsor)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fullName, email, number, subject, message, type } = formData;

    const payload = {
      full_name: fullName,
      email,
      number,
      subject,
      message,
      type,
    };

    try {
      const response = await fetch('https://dev-ipkl.ipkl.in/api/resource/IPKL%20ENQUIRY', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success(`Successfully submitted !`);
      } else {
        toast.error('Failed to submit. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('An error occurred. Please try again.');
    }
  };



      

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <>


<Helmet>

<meta charSet="utf-8" />
        <title>
        Contact Us - Indian Premier Kabaddi League (IPKL) Support and Inquiries


        </title>
        <link rel="canonical" href="https://ipkl.in/contact" />
        <meta
          name="description"
          content="Contact the Indian Premier Kabaddi League (IPKL) for inquiries, partnerships, and more. Connect with our team for all IPKL-related information and support.

"
        />
        <meta
          name="keywords"
          content=" Ipkl standings 2024
IPKL points table 2024
IPKL live score
kabaddi live updates

"
        />
      </Helmet>


    <Breadcumb page="Contact Us" title="Get in touch for all inquiries."/>
<ToastContainer position="bottom-right"/>

      <section className="form-sponsor">
        <div className="container-fluid">
        <div className="row">
      <div className="col-md-12 col-12 align-self-center">
        <div className="form-style">
        <div className='headingsection mx-auto'>
        <img src={arrow} className="img-fluid"/>
        <h4 className="text-white">Interested in Partnering With Us</h4>

        <p className="emailcontact">Support Email :  indianpremierkabaddileague@gmail.com</p>
      </div>


          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${formData.type === 'Sponsor' ? 'active' : ''}`}
                id="form2-tab"
                data-bs-toggle="tab"
                type="button"
                role="tab"
                onClick={() => handleTabChange('Sponsor')}
              >
                Sponsors
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${formData.type === 'Media' ? 'active' : ''}`}
                id="form1-tab"
                data-bs-toggle="tab"
                type="button"
                role="tab"
                onClick={() => handleTabChange('Media')}
              >
                Enquiry 
              </button>
            </li>
          </ul>

          <div className="tab-content mt-3" id="myTabContent">
            <div
              className={`tab-pane fade ${formData.type === 'Media' ? 'show active' : ''}`}
              id="form1"
              role="tabpanel"
              aria-labelledby="form1-tab"
            >
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="tel"
                      minLength={10}
                      maxLength={10}
                      className="form-control"
                      placeholder="Phone Number"
                      name="number"
                      value={formData.number}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <textarea
                      className="form-control"
                      placeholder="Message"
                      name="message"
                      rows="3"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className="col-12 mt-3">
                    <button type="submit" className="btn btn-primary">
                     Submit Now
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              className={`tab-pane fade ${formData.type === 'Sponsor' ? 'show active' : ''}`}
              id="form2"
              role="tabpanel"
              aria-labelledby="form2-tab"
            >
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="tel"
                      minLength={10}
                      maxLength={10}
                      className="form-control"
                      placeholder="Phone Number"
                      name="number"
                      value={formData.number}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <textarea
                      className="form-control"
                      placeholder="Message"
                      name="message"
                      rows="3"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className="col-12 mt-3">
                    <button type="submit" className="btn btn-primary">
                      Apply for {formData.type}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
      </section>
      <div className="col-md-12 col-12">
        <div className="addressinfo">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10454.458000082173!2d76.867811!3d30.6844591!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f937e7baebf03%3A0x777ecd024cb43a34!2sTau%20Devi%20Lal%20Stadium!5e1!3m2!1sen!2sin!4v1725280427837!5m2!1sen!2sin"
         width="100%"
         height="450"
         allowfullscreen=""
         loading="lazy"
         referrerpolicy="no-referrer-when-downgrade"
       ></iframe>
        </div>
      </div>
    </>
  );
}
