import React from 'react'
import sponsorimg from './sponsors-002.png'
import logo1 from './fancode (1).png'
import logo2 from './star (1).png'
import logo3 from './kabaddi 360.png'
import arrow from "./arrowabtract.png";
import sponsor from './ADT sports (2).png';
import sponsor5 from './Kinetic-Sports-jpg.jpg-500.jpg';
import sponsor6 from './Paras-logo.jpg';
import redbull from './red-bull.png'
export default function Sponsor() {
  return (
  <section className='sponsorship-bg'>
      <br></br>

<div className='container-fluid'>
    <div className='row'>
    <div className='headingsection mx-auto'>
        <img src={arrow} className="img-fluid"/>
        <h4 className="text-black">OUR PARTNERS</h4>
      </div>
 <center>
 <div className='imagelogo mx-auto'>
            <br></br>
            <div className="row  justify-content-center">
          
          
           <div className="col-md-2 col-4">
           <div className="footer-sponsor">
              <img src={logo2} className="img-fluid"/>
              <p>Broadcasting  Partner</p>
           
            </div>
           </div>

           {/* <div className="col-md-2 col-4">
           <div className="footer-sponsor">
              <img src={logo1} className="img-fluid"/>
              <p>OTT Partner</p>
            </div>
           </div> */}


         

           <div className="col-md-2 col-4">
           <div className="footer-sponsor">
              <img src={logo3} className="img-fluid"/>
              <p>Outreach  Partner</p>
           
            </div>
           </div>

           <div className="col-md-2 col-4">
           <div className="footer-sponsor">
              <img src={redbull} className="img-fluid"/>
              <p>Energy Drink Partner</p>
           
            </div>
           </div>


           <div className="col-md-2 col-4">
           <div className="footer-sponsor">
              <img src={sponsor6} className="img-fluid"/>
              <p>Health  Partner</p>
           
            </div>
           </div>
        

           
           <div className="col-md-2 col-4">
           <div className="footer-sponsor">
              <img src={sponsor5} className="img-fluid"/>
              <p>Brand & Communication Partner</p>
           
            </div>
           </div>
          </div>
            </div>
 </center>
    </div>
</div>

  </section>
  )
}
