import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./App.css";
import banner1 from "./catch us mob.jpg"; 
// import banner2 from "./Mobile-cover--400x450.jpg"; 
import banner0 from "./mob1.jpg"; 
import banner2 from "./mob2.jpg"; 


// Local image for the first banner

const options = {
  items: 1,
  loop: true,
  autoplay: true,
  autoplayTimeout: 6000,
  dots: false,
  nav: false,
  animateOut: "fadeOut",
};

const thumbnails = [

  // {
  //   id: 0,
  //   imgSrc: banner0,
  //   title: "Mega Event on 12th September! All eyes on Chandigarh  for the spectacular unveiling of the Trophy, Jersey, and Teams",
  //   shorttile:"Mega Event on 12th September ",
  // }, // Local image
  {
    id: 1,
    imgSrc: banner0,
    // title: "Indian Premier Kabaddi League 2024 kicks off on October 4th, uniting top talent for an epic showdown",
    shorttile:"Indian Premier Kabaddi League 2024 ",
  }
,
{
  id: 1,
  imgSrc: banner2,
  // title: "Indian Premier Kabaddi League 2024 kicks off on October 4th, uniting top talent for an epic showdown",
  shorttile:"Indian Premier Kabaddi League 2024 ",
}

];

function MobileBanner() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleThumbnailClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="App d-sm-block d-md-none">
      <div className="carousel-container">
        <OwlCarousel
          className="owl-theme"
          {...options}
          onTranslated={(e) => setCurrentSlide(e.item.index)}
          startPosition={currentSlide}
        >
          {thumbnails.map((thumb, index) => (
            <div key={thumb.id} className="item">
              <img
                src={thumb.imgSrc}
                alt={`Slide ${index + 1}`}
                style={{ width: "100%", height: "100%" }}
              />
              <div className="carousel-content">
                <h2>{thumb.title}</h2>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>

      <div className="thumbnails">
        {thumbnails.map((thumb, index) => (
          <div key={thumb.id} className="image-container">
            <img
              src={thumb.imgSrc}
              alt={`Thumbnail ${index + 1}`}
              className={currentSlide === index ? "thumbnail active" : "thumbnail"}
              onClick={() => handleThumbnailClick(index)}
              style={{
                width: "192px",
                margin: "5px",
                cursor: "pointer",
                borderbottom: currentSlide === index ? "2px solid #" : "2px solid transparent",
              }}
            />
            <p>{thumb.shorttile}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

    export default MobileBanner;
