import React from "react";
import Header from "./Header";
import aboutplayer from "./player-running.png";
import founderimg from "./founder-image.png";
import quote from "./quote.png";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./Footer";
import CarouselComponent from "./CarouselComponent";
import Breadcumb from "./Breadcumb";
import arrow from "./arrowabtract.png";
import logo1 from "./arrownewbg.png";
import logo2 from "./Frame 221.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Helmet } from "react-helmet";
import Founder from "./Founder";

export default function About() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);


  const options = {
    items: 3.5,
    loop: true,
    autoplay: true,
    autoplayTimeout: 500000000,
    dots: false,
    margin: 20,
    nav: false,
    animateOut: "fadeOut",
    responsive: {
      0: {
        items: 1, // 1 item for screens 0px and up
      },
      600: {
        items: 1, // 2 items for screens 600px and up
      },
      1000: {
        items: 1, // 3.5 items for screens 1000px and up
      }
    }
  };


  return (
    <>


<Helmet>
        <meta charSet="utf-8" />
        <title>
        Indian Premier Kabaddi League (IPKL) - Founded by Pradeep Kumar | Learn More About Us

        </title>
        <link rel="canonical" href="https://ipkl.in/about" />
        <meta
          name="description"
          content="Discover the story behind the Indian Premier Kabaddi League (IPKL) and the vision of Pradeep Kumar. Learn about our mission, values, and the journey of IPKL on the official IPKL website."
        />
        <meta
          name="keywords"
          content="
Indian Premier Kabaddi League(IPKL)
Pradeep Kumar
IPKL website
"
        />
      </Helmet>



    <Breadcumb page="About Us" title="IPKL promoting talent and passion"/>

      <div className="aboutsection">
        <div className="container-fluid">
          <div className="row fr">
            <div className="col-md-6 align-content-center">
              <div
                className="aboutleft-content"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <h2>
                  Indian Premier<br></br> Kabaddi League
                </h2>

                <p>
                  The Indian Premier Kabaddi League (IPKL) is a significant
                  platform dedicated to showcasing the talents of young kabaddi
                  players, a traditional Indian sport that has often been
                  overlooked on larger stages. The league encourages
                  participation from athletes across India, offering them a
                  valuable opportunity to develop and enhance their skills in a
                  competitive setting.
                </p>
                <p>
                  Founded with the mission of nurturing emerging talent, the
                  IPKL provides a stage for these players to demonstrate their
                  potential. This initiative aims to elevate the profile of
                  kabaddi not only within India but also on an international
                  level. By creating strategic opportunities and exposure for
                  aspiring athletes, the IPKL contributes to the sport's growth
                  and recognition worldwide.
                </p>
              </div>
            </div>

            <div className="col-md-6 align-self-center">
              <div className="aboutright">
                <img src={aboutplayer} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="mission-vision">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="mission">
                <div className="mission-heading">
                  <div className="arrowtop">
                    <img src={arrow} className="img-fluid" />
                    <h4>Our Mission</h4>{" "}
                  </div>
                </div>
                <div className="miissbox">
                  <div className="row">
                    <div className="col-md-2 col-12 align-self-center">
                   <div className="logomission">
                   <img src={logo1} className="img-fluid" />
                   </div>
                    </div>
                    <div className="col-md-10 col-12">
                      <div className="mission-para">
                        <p>
                        To find the Kabaddi talent across the country. Providing state of the art training facility to the amateur Kabaddi Players. Building true stage to highlight talent of youngsters of India. Uniting the player to enhance the sports of Kabaddi.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mission">
                <div className="mission-heading">
                  <div className="arrowtop">
                    <img src={arrow} className="img-fluid" />
                    <h4>Our Vision</h4>{" "}
                  </div>
                </div>

                <div className="miissbox">
                  <div className="row">
                    <div className="col-md-2 col-12 align-self-center">
                    <div className="logomission">
                    <img src={logo2} className="img-fluid" />

</div>
                    </div>
                    <div className="col-md-10 col-12">
                      <div className="mission-para">
                        <p>
                          To provide a platform to undiscovered Kabaddi talent
                          across India though transparent and relevant selection
                          processes. Featuring talented Kabaddi players on
                          screen from telecast / streaming from Phase – 1 to the
                          final league matches which will be further telecasted
                          on television.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


  <Founder/>
    </>
  );
}
