import React, { useState, useEffect } from "react";
import Breadcumb from "./Breadcumb";
import Header from "./Header";
import { Link } from "react-router-dom";
import blogimg from "./blogimage.jpg";
import { Helmet } from "react-helmet";
export default function New() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-ipkl.ipkl.in/api/resource/News%20and%20Updates?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedData = data.data.sort((a, b) => a.position - b.position);

        setStandings(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  return (
    <>
      <Breadcumb
        page="News & Articles"
        title="Stay updated with the latest IPKL news"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Latest IPKL News | IPKL Updates & Announcements</title>
        <link rel="canonical" href="https://ipkl.in/news" />
        <meta
          name="description"
          content="Join the excitement of IPKL 2024 at the Tau Devi Lal Stadium, Panchkula! Get the latest updates, match news and announcements from the Indian Premier Kabaddi League. Stay informed and experience Kabaddi 2024 like never before. "
        />
        <meta
          name="keywords"
          content=" Ipkl standings 2024 IPKL points table 2024 IPKL live score kabaddi live updates"
        />
      </Helmet>

      <div className="news-section">
        <div className="container-fluid">
          <div className="row">
            {standings.length > 0 ? (
              standings.map((team, index) => (
                <div className="col-md-3 col-12">
                  <Link to={team.news_links} target="_blank">
                    <div className="bloginformation">
                      <div className="img">
                        <img
                          src={`https://dev-ipkl.ipkl.in/${team.image}`}
                          className="img-fluid"
                        />
                      </div>
                      <div className="blogpara">
                        <p>
                          {team.date} | {team.channel}
                        </p>
                        <h2>
                          {team.title.split(" ").slice(0, 4).join(" ")}{" "}
                          {/* Extract first 30 words */}
                          {team.title.split(" ").length > 30 ? "." : ".."}{" "}
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p>Loading..</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
