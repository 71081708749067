// App.js

import React from "react";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Footer from "./Footer";
import { useEffect } from "react";

import Header from "./Header";
import Comingsoon from "./Comingsoon";
import Contact from "./Contact";
import StandingPage from "./StandingPage";
import Team from "./Team";
import Matches from "./Matches";
import Outlets from "./Outlets";
function App() {
  return (
    <>
      <Router>
        <Header />
        <Outlets />
        <Footer />
      </Router>
    </>
  );
}

export default App;
