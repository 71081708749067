import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Comingsoon() {
  return (
    <>
    
    <Header/>

    <div className='topdivider'>

    </div>
    <section className='coming-soon'>
        <h4>Coming Soon</h4>
    </section>


<Footer/>
    </>
  )
}
