import React from 'react'
import arrow from './arrowabtract.png'
export default function (props) {
  return (
   <>
      <div className="topdivider"></div>

      <section id="breadcrumb-container">
        <div class="breadcrumb-content">

           <div className='arrowtop'>
           <img src={arrow}  className='img-fluid'/>
           <h1>{props.page}</h1>
           </div>
           <div className='linebottom'>
           <p>{props.title}</p>

           <div className='line-yellow'></div>
           </div>
        </div>
    </section>
   </>
  )
}
