import React, { useState, useEffect } from 'react';

export default function Heroblog() {
  // State to hold the latest blog data
  const [latestBlog, setLatestBlog] = useState(null);

  // Function to fetch the latest blog data from the API
  const fetchLatestBlog = async () => {
    try {
      const response = await fetch(
        'https://dev-ipkl.ipkl.in/api/resource/Hero%20Blog?fields=["*"]'
      );
      const data = await response.json();

      // Assuming the response contains an array of blogs, we pick the first one
      if (data && data.data && data.data.length > 0) {
        setLatestBlog(data.data[0]);
      }
    } catch (error) {
      console.error('Error fetching the latest blog:', error);
    }
  };

  // Fetch the blog data when the component mounts
  useEffect(() => {
    fetchLatestBlog();
  }, []);

  return (
    <div className="hero-section">
      {latestBlog ? (
        <>
          <img
            src={`https://dev-ipkl.ipkl.in/${latestBlog.hero_image}`}
            alt="Hero Background"
            className="hero-image"
          />
          <div className="hero-overlay"></div>
          <div className="hero-content">
            <p>{latestBlog.date ? latestBlog.date : 'Date not available'} | IPKL Team</p>
            <h1>{latestBlog.blog_title}</h1>

          <div className='yellowherosectionline'></div>

          </div>

        </>
      ) : (
        <p>Loading hero section...</p>
      )}
    </div>
  );
}
