import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import arrow from "./arrowabtract.png";
import leftArrow from "./arrowleft.svg"; // Import left arrow image
import rightArrow from "./righticon.svg"; // Import right arrow image

export default function Fixture() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(`https://dev-ipkl.ipkl.in/api/resource/FIXTURES?fields=[%22*%22]&limit_page_length=100`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedData = data.data.sort((a, b) => a.match_number - b.match_number);
        setStandings(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const now = new Date();
  const currentDay = now.getDate();
  const currentMonth = now.getMonth() + 1; // getMonth is zero-indexed
  const currentYear = now.getFullYear();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dayOptions = { weekday: "long" };
    const dateOptions = { year: "numeric", month: "long", day: "numeric" };
    return {
      day: date.toLocaleDateString("en-US", dayOptions),
      date: date.toLocaleDateString("en-US", dateOptions),
    };
  };

  const options = {
    items: 3.5,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    margin: 20,
    nav: true,
    navText: [
      `<img src='${leftArrow}' class='custom-nav-icon' alt='left arrow' />`,
      `<img src='${rightArrow}' class='custom-nav-icon' alt='right arrow' />`
    ],
    animateOut: "fadeOut",
    responsive: {
      0: {
        items: 1, // 1 item for screens 0px and up
      },
      600: {
        items: 2, // 2 items for screens 600px and up
      },
      1000: {
        items: 3.5, // 3.5 items for screens 1000px and up
      }
    }
  };

  return (
    <section className='fixturessection'>
      <div className='headingsection'>
        <img src={arrow} className="img-fluid"/>
        <h4>MATCH SCHEDULE</h4>
      </div>
      <div className='fixtures'>
        {loading ? (
          <div className="loader">Loading...</div> // Show loader when data is being fetched
        ) : (
          <OwlCarousel className="owl-theme" {...options}>
            {standings.length > 0 ? (
              standings.map((team, index) => 
              (
                <div className='fixturecard' key={index}>
                  <div className='row'>
                    <div className='col-7'>
                      <div className='leftbox'>
                        <p>Tau Devi Stadium, Panchkula, Haryana, India</p>
                        <p className='p1'>Time : XX:XX</p>
                      </div>
                    </div>
                    <div className='col-5'>
                      <div className='rightbox'>
                        <button>{`Day -, Match --`}</button>
                      </div>
                    </div>

                    <div className='separateline'></div>

                    <div className='scoreteam'>
                      <div className='row'>
                        <div className='col-3'>
                          <div className='teamlogo'>
                            <img src={`https://dev-ipkl.ipkl.in/${team.team_a_logo}`} className='img-fluid' alt="Team A" />
                          </div>

                        </div>
                        <div className='col-6'>
                          <div className='teamscore'>
                            <h4>{team.team_a_score}VS{team.team_b_score}</h4>
                            {/* <p>{formatDate(team.match_date).day}</p> */}
                            <p>Time - xx</p>

                            {/* <p className="lightweight">{formatDate(team.match_date).date}</p>
                             */}
                            <p className="lightweight">xx:xx</p>

                          </div>
                        </div>
                        <div className='col-3'>
                          <div className='teamlogo'>
                          <img src={`https://dev-ipkl.ipkl.in/${team.team_b_logo}`} className='img-fluid' alt="Team A" />

                          </div>
                          <div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className='fulltime'>Schedule
                  </button>
                </div>
              ))
            ) : (
              <div>No match data available</div>
            )}
          </OwlCarousel>
        )}
      </div>
    </section>
  );
}
