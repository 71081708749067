import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Heroblog from "./Heroblog";

export default function Blog() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [latestBlog, setLatestBlog] = useState(null);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          "https://dev-ipkl.ipkl.in/api/resource/IPKL%20Bog?fields=[%22*%22]&title%20DESC"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        const sortedData = data.data.sort((a, b) => a.position - b.position);
        setStandings(sortedData);
        setLoading(false);
        if (sortedData.length > 0) {
          setLatestBlog(sortedData[0]); 
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>IPKL Blog: Latest News, Updates, and Event Highlights</title>
        <link rel="canonical" href="https://ipkl.in/blog" />
        <meta
          name="description"
          content="Explore the IPKL blog for the latest news, in-depth updates, and comprehensive coverage of all the events and matches from the Uttar Pradesh Kabaddi League."
        />
        <meta
          name="keywords"
          content="Ipkl standings 2024, IPKL points table 2024, IPKL live score, kabaddi live updates"
        />
      </Helmet>
      <Heroblog />
      <div className="blog-section">
        <div className="container-fluid">
          <div className="row">
            {/* Blog posts section */}
            <div className="col-md-9">
              <div className="row">
                {standings.length > 0 ? (
                  standings.map((team, index) => (
                    <div key={index} className="col-md-6 col-lg-4 col-12 mb-4">
                      <Link to={`/single-blog?name=${team.name}`}>
                        <div className="bloginformation">
                          <div className="img">
                            <img
                              src={`https://dev-ipkl.ipkl.in/${team.short_image_blog}`}
                              className="img-fluid"
                              alt="Blog"
                            />
                          </div>
                          <div className="blogpara">
                            <p>
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                              &nbsp; {team.date} | IPKL Team
                            </p>
                            <div className="lineyellowblog"></div>
                            <h2>
                              {team.title.split(" ").slice(0, 12).join(" ")}{" "}
                              {team.title.split(" ").length > 8 ? "..." : ""}
                            </h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                ) : (
                  <p>Loading..</p>
                )}
              </div>
            </div>

            {/* Sidebar section */}
            <div className="col-md-3">
              <div className="sidebar-blog">
                <ul>
                  {standings.length > 0 &&
                    standings.slice(0, 5).map((post, index) => (
                      <li key={index}>
                        <div className="line-blog"></div>
                        <Link to={`/single-blog?name=${post.name}`}>
                          <div className="sidebarcard">
                            <p>{post.name}</p>
                            <p className="sidebarreadmore">Read More</p>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
